// api.js
import axios from "axios";
import Cookies from "js-cookie";
import { AUTH_MS_URL, RIDE_MS_URL } from "./constants";

const authToken = Cookies.get("oya_token");
const apiRequest = axios.create({
  baseURL: RIDE_MS_URL,
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});

export const authApiRequest = axios.create({
  baseURL: AUTH_MS_URL,
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});
// Request interceptor
// apiRequest.interceptors.request.use(
//   (config) => {
//     // Modify the request config here if needed

//     return config;
//   },
//   (error) => {
//     // Handle request errors here
//     //if (location.pathname) return Promise.reject(error);
//     return error;
//   }
// );

// Response interceptor
// apiRequest.interceptors.response.use(
//   (response) => {
//     // if (response.data.status !== 200) {
//     //   handleLogout();
//     // }
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       // Redirect to the login page
//       console.log(error.response);
//     }
//     return error;
//     //return Promise.reject(error);
//   }
// );

export default apiRequest;
