import { func } from "../../utils";
import { authApiRequest } from "../../utils/apiRequests";
import { SIGNIN_SUCCESS, SIGNOUT_SUCCESS, UPDATE_PROFILE } from "../_types";

export const getUser = (userId) => {
  return (dispatch) => {
    authApiRequest.get(`/v2/accounts/${userId}`).then((res) => {
      dispatch({
        type: SIGNIN_SUCCESS,
        payload: res,
      });
    });
  };
};

export const signInSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: SIGNIN_SUCCESS,
      payload: { data },
    });
  };
};

export const signOutSuccess = () => {
  return async (dispatch) => {
    func.delCookie("token");
    func.delCookie("user");
    func.delCookie("usr");
    func.delCookie("role");
    func.delCookie("appref");

    const logout = await dispatch({ type: SIGNOUT_SUCCESS });
    if (logout)
      func.redirect(
        func.app.accounts_url +
          "login" +
          "?appref=" +
          window.location.origin +
          "/"
      );
  };
};

export const profileUpdated = (payload) => {
  return (dispatch) => {
    func.setCookieJson("user", payload);
    dispatch({ type: UPDATE_PROFILE, payload });
  };
};
